import { Assignment, Link } from "@mui/icons-material";
import { ReactNode } from "react";
import {
  BiCalendar,
  BiFile,
  BiHomeAlt,
  BiNews,
  BiPen,
  BiPencil,
  BiScreenshot,
  BiToggleLeft,
  BiUser,
  BiUserPlus
} from "react-icons/bi";
import { FaCommentAlt, FaTags } from "react-icons/fa";
import { Role } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id?: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: Role[];
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] = [
  {
    title: "Sample",
    messageId: "sidebar.menu.inicio",
    type: "group",
    children: [
      {
        title: "Page 2",
        messageId: "sidebar.menu.page.dashboard",
        type: "item",
        icon: <BiHomeAlt />,
        url: "/news"
      }
    ]
  },

  // Menu conteúdo
  {
    title: "Conteudo",
    messageId: "sidebar.menu.conteudo",
    type: "group",
    children: [
      {
        title: "Links",
        messageId: "sidebar.nav.bi",
        type: "item",
        icon: <Link />,
        url: "/links"
      },
      {
        title: "Notícias",
        messageId: "sidebar.nav.noticias",
        type: "item",
        icon: <BiNews />,
        url: "/noticias"
      },
      {
        title: "Arquivos",
        messageId: "sidebar.nav.files",
        type: "item",
        icon: <BiFile />,
        url: "/arquivos"
      },
      {
        title: "Gerenciador de Arquivos",
        messageId: "sidebar.nav.files.explorer",
        type: "item",
        icon: <BiFile />,
        url: "/files"
      },
      {
        title: "Canais",
        messageId: "sidebar.nav.channels",
        type: "item",
        icon: <BiScreenshot />,
        url: "/canais"
      },
      // {
      //   title: "Calendário",
      //   messageId: "sidebar.nav.events.calendar",
      //   type: "item",
      //   icon: <BiCalendar />,
      //   url: "/calendario"
      // },
      {
        title: "Documentos",
        messageId: "sidebar.nav.contratos",
        type: "item",
        icon: <Assignment />,
        url: "/contratos"
      },
      {
        title: "Assinaturas",
        messageId: "Assinaturas",
        type: "item",
        icon: <BiPen />,
        url: "/assinaturas"
      }
    ]
  },

  {
    title: "Cadastros",
    messageId: "sidebar.menu.manutencao",
    type: "group",
    permittedRole: [Role.Admin],
    children: [
      {
        title: "Usuários",
        messageId: "sidebar.nav.usuarios",
        type: "item",
        icon: <BiUser />,
        url: "/usuarios"
      },
      {
        title: "Pré cadastro Usuários",
        messageId: "sidebar.nav.pre.cadastro.usuarios",
        type: "item",
        icon: <BiUser />,
        url: "/usuarios-pre-cadastro"
      },
      {
        title: "Perfis",
        messageId: "sidebar.nav.perfis",
        type: "item",
        icon: <BiUserPlus />,
        url: "/perfis"
      },

      {
        title: "Categorias",
        messageId: "sidebar.nav.categories",
        type: "item",
        icon: <FaTags />,
        url: "/categorias"
      },
      {
        title: "Sugestões",
        messageId: "sidebar.nav.sugestoes",
        type: "item",
        icon: <FaCommentAlt />,
        url: "/sugestoes"
      }
    ]
  },

  {
    title: "Sample",
    messageId: "sidebar.menu.config",
    type: "group",
    permittedRole: [Role.Admin],
    children: [
      {
        title: "Page 3",
        messageId: "sidebar.menu.page.parameters",
        type: "item",
        icon: <BiToggleLeft />,
        url: "/parametros_de_sistema"
      }
    ]
  }
];
export default routesConfig;
